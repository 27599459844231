export const LeftSideBarTabs = [
    {
        name:"Home",
        icon:"home",
        link:""
    },
    {
        name:"My Markets",
        icon:"mymarkets",
        link:"/my-markets",
        checkLogin:true,
    },
    // {
    //     name:"Premium Sportbook",
    //     icon:"mymarkets",
    //     link:"/sportbook",
    //     checkLogin:true,
    // },
    {
        name:"Cricket",
        icon:"cricket",
        link:`/sport/${4}`,
        id: 4,
    },
    {
        name:"Live Casino",
        icon:"livecasino",
        link:"/live-casino"
    },
    {
        name:"Football",
        icon:"football",
        link:`/sport/${1}`,
        id: 1,
    },
    {
        name:"Tennis",
        icon:"tennis",
        link:`/sport/${2}`,
        id: 2,
    },
    {
        name:"Horse Racing",
        icon:"horse racing",
        link:`/racing/${7}`,
        id: 7,
    },
    {
        name:"Greyhound Racing",
        icon:"greyhound racing",
        link:`/racing/${4339}`,
        id: 4339,
    },
    // {
    //     name:"E-Sports",
    //     icon:"esports",
    //     link:`/sport/${12121}`,
    //     id: 12121,
    // },
    {
        name:"Slots",
        icon:"slots",
        link:"/live-casino/tab/100010"
    },
    // {
    //     name:"Genie Bet",
    //     icon:"genie",
    //     link:""
    // },
]
export const RightSideBarTabs = [
    {
        name:"Open Bets",
        icon:"openbets",
        link:"my-bets"
    },
    {
        name:"Betting P&L",
        icon:"pnl2",
        link:"betting-pnl"
    },
    {
        name:"Transfer Statement",
        icon:"transferstatement",
        link:"transfer"
    },
    {
        name:"Time Setting",
        icon:"time",
        link:"time-settings"
    },
    {
        name:"Change Password",
        icon:"lock",
        link:"change-password"
    },
    {
        name:"Rules & Regulations",
        icon:"rules",
        link:"rules-regs"
    },
    {
        name:"KYC",
        icon:"rules",
        link:"kyc"
    },
    {
        name:"Resopnsible Gambling",
        icon:"rules",
        link:"responsible-gambling"
    },
    {
        name:"Exclusion Policy",
        icon:"rules",
        link:"exclusion-policy"
    },
    {
        name:"Signout",
        icon:"signout",
        link:""
    },
    
]