import { createSlice } from '@reduxjs/toolkit';

const initialState ={ 
    bets:[],
};


const betsSlice = createSlice({
    name:"bets",
    initialState:initialState,
    reducers:{
        addBet:(state,action)=>{
            state.bets.push(action.payload); 
        },
        removeBet:(state,action)=>{
            state.bets = state.bets.filter((bet)=>bet.betId !== action.payload);
        },
        updateAmount:(state,action)=>{
            const temp = state.bets;
            temp.forEach((bet)=>{
                if(bet.betId === action.payload.betId)
                {
                    bet.amount = action.payload.amount;
                }
            });
            state.bets = temp;
        },
        clearBets:()=>initialState
    }
});

export const {addBet,clearBets,removeBet,updateAmount} = betsSlice.actions;

export default betsSlice.reducer;