import { Box, Button, Divider, Drawer, Stack, Typography } from "@mui/material";
import {  useNavigate } from "react-router-dom";
import {  useSelector } from "react-redux";

import CustomIcon from "./customIcon";
const LeftSideBar = ({openLeftNav,setOpenLeftNav=()=>{}})=>{
    
    return(
        <>
            <Drawer
                SlideProps={{
                    easing:"cubic-bezier(0, 0, 0.2, 1)",
                    timeout:300,
                }}
                sx={{
                width: 260,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: 260,
                    boxSizing: 'border-box',
                },
                }}
                onClose={()=>setOpenLeftNav(false)}
                open={openLeftNav}
            >
                <LeftSideBarContent setOpenLeftNav={setOpenLeftNav} />
            </Drawer>
        </>
        
    )
}

export const LeftSideBarContent = ({setOpenLeftNav=()=>{}})=> {
    const navigate = useNavigate()
    const { isLogginIn } = useSelector(state=>state.user);
    const { menu } = useSelector(state=>state.sports);


    const renderMenuItem = (info) =>{

        let link = info.link

        if(!info.link){
            if(info.type==="Casino") link = `/live-casino/${info.provider_id}`
            else if(info.type==="Sport"){
                if(info.provider_id === "7" || info.provider_id === "4339"){
                    link = `/racing/${info.provider_id}`
                }
                else{
                    link = `/sport/${info.provider_id}`
                }
            }
        }

        return(
            <>
                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{padding:"11px 0px"}} onClick={()=>{navigate(`/m${link}`);setOpenLeftNav(false)}}>
                    <CustomIcon name={info?.icon} fontSize={20}/>
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                    <Typography sx={{textTransform:"capitalize"}} >{info?.menu_name} </Typography>
                    {
                        info.is_new &&
                    <span className="new-label">New</span> 
                    }
                    </Stack>
                </Stack>
                <Divider/>
            </>
        )
    }

    return(
        <Box sx={{padding:"0px 16px",minWidth: 236,bgcolor:"white",boxSizing: 'border-box',
        position: 'sticky',
        top: '0',
        overflowY: 'auto',
        }}>
        <>
                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{padding:"11px 0px"}} onClick={()=>{navigate("/m");setOpenLeftNav(false)}}>
                    <CustomIcon name={"home"} fontSize={20}/>
                    <Typography >{"Home"}</Typography>
                </Stack>
                <Divider/>
            </>
        {
            isLogginIn && (
            <>
                <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{padding:"11px 0px"}} onClick={()=>{navigate("/m/my-markets");setOpenLeftNav(false)}}>
                    <CustomIcon name={"mymarkets"} fontSize={20}/>
                    <Typography >{"My Markets"}</Typography>
                </Stack>
                <Divider/>
            </>
            )
        }
    {menu?.map((info, index) => renderMenuItem(info))}
    {/* {LeftSideBarTabs.filter((i)=>isLogginIn || (!isLogginIn && !i.checkLogin)).map((info, index) => (
        <>
            <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{padding:"11px 0px"}} onClick={()=>{navigate(info?.link);setOpenLeftNav(false)}}>
                <CustomIcon name={info?.icon} fontSize={20}/>
                <Typography >{info?.name}</Typography>
            </Stack>
            <Divider/>
        </>

    ))} */}
    </Box>
    )
}

export default LeftSideBar;