import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sports:[],
    category:[],
    casino:[],
    menu:[],
};


const sportsSlice = createSlice({
    name:"sports",
    initialState:initialState,
    reducers:{
        saveCategories:(state,action)=>{
            state.category = action.payload
        },
        saveSports:(state,action)=>{
            state.sports = action.payload
        },
        saveCasino:(state,action)=>{
            state.casino = action.payload
        },
        saveMenu:(state,action)=>{
            state.menu = action.payload
        },
        clearSports:(state)=>({...initialState,menu:state.menu})
    }
});

export const {saveCategories,saveSports,saveCasino,clearSports,saveMenu} = sportsSlice.actions;

export default sportsSlice.reducer;