import moment from "moment";
import store from "../redux/store";
import zlib from 'react-zlib-js';
import { Buffer } from 'buffer';
import axios from "axios";

export const xorDecrypt = (encryptedText, key) => {
  let decrypted = '';
  for (let i = 0; i < encryptedText.length; i++) {
    decrypted += String.fromCharCode(encryptedText.charCodeAt(i) ^ key.charCodeAt(i % key.length));
  }
  return decrypted;
}

export const decodeV1Response = (encodeResponse) => {
  const brCompressed = Buffer.from(encodeResponse, 'base64');
  const decodeData = zlib.gunzipSync(brCompressed);
  const jsonString = decodeData.toString();
  const key = process.env.REACT_APP_SECRET_KEY;
  const decryptedData = xorDecrypt(jsonString, key);
  return JSON.parse(decryptedData)
}

const xorEncrypt = (text, key) => {
  let encrypted = '';
  for (let i = 0; i < text.length; i += 1) {
    encrypted += String.fromCharCode(text.charCodeAt(i) ^ key.charCodeAt(i % key.length));
    // const charCodeText = text.charCodeAt(i);
    // const charCodeKey = key.charCodeAt(i % key.length);
    // const xorResult = (charCodeText | charCodeKey) & ~(charCodeText & charCodeKey);
    // encrypted += String.fromCharCode(xorResult);
  }
  return encrypted;
};

export const encodeV1Response = (response) => {
  try {
    const jsonString = JSON.stringify(response);
    const encryptedData = xorEncrypt(jsonString, process.env.REACT_APP_SECRET_KEY);
    const responseData = zlib.gzipSync(encryptedData).toString('base64');
    // const responseData = zlib.deflateSync(encryptedData).toString('base64');
    // const responseData = zlib.brotliCompressSync(jsonString).toString('base64');
    return {data:responseData};
  } catch (error) {
    console.log(error);
    throw "something went wrong";
  }
};

export const handleResponse = (res,isEncypted=false) => {
  if (res.status_code === 200 && !res.response_error) {
    console.log(res.status_message);
    return isEncypted? decodeV1Response(res.data) : res.data;
  } else {
    throw new Error(res.status_message || 'Unknown error occurred');
  }
}

export const handleCatch = (res) => {
  if (res.response.status == 401) {
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace("/");
  }
  if (res?.response?.data?.status_message) {
    throw res.response.data.status_message
  }
  else throw "Something went wrong"
}

export const groupBy = (array, key, commonKeys = []) => {
  // First, group by the key
  const grouped = array.reduce((result, obj) => {
    (result[obj[key]] = result[obj[key]] || []).push(obj);
    return result;
  }, {});

  // Then, convert the object back into an array
  return Object.entries(grouped).map(([keyValue, data]) => {
    const commonFields = {};
    commonKeys.forEach((commonKey) => {
      if (data.length > 0 && data[0].hasOwnProperty(commonKey)) {
        commonFields[commonKey] = data[0][commonKey];
      }
    });

    return {
      [key]: keyValue,
      ...commonFields,
      data,
    };
  });
};

export const convertKeyInArrayOfObjects = (arrayOfObjects, oldKey, newKey) => {
  return arrayOfObjects.map((obj) => {
    const { [oldKey]: value, ...rest } = obj;
    return { ...rest, [newKey]: value };
  });
}


export const findByKeyInArray = (array = [], key, value) => {
  return array.find((ele) => ele[key] === value);
}

export const addNewField = (objects, fieldName, calculation) => {
  return objects.map(obj => {
    // Use the provided calculation function to compute the new field's value
    const newValue = calculation(obj);

    // Assigning the new value to the specified field name
    return {
      ...obj,
      [fieldName]: newValue
    };
  });
}

export const formatUnixTimestamp = (unixTimestamp) => {
  const { settings } = store.getState().user;
  let timestamp = moment.unix(unixTimestamp).utcOffset(settings?.utc.value);

  if (timestamp.isSame(moment(), 'day')) {
    return 'Today ' + timestamp.format('HH:mm');
  } else if (timestamp.isSame(moment().add(1, 'days'), 'day')) {
    return 'Tomorrow ' + timestamp.format('HH:mm');
  } else {
    return timestamp.format('DD/MM/YY HH:mm');
  }
}

export const timeDifferenceFromNow = (unixTimestamp) => {
  let timestamp = moment.unix(unixTimestamp);
  let now = moment();

  let diff = moment.duration(timestamp.diff(now));

  let hours = diff.hours();
  let minutes = diff.minutes();

  return `${hours}hr ${minutes}mins`;
}

export const showNumberWithDecimal = (number) => {
  console.log(number);
  // Check if the number has fractions (decimal places)
  if (Number.isInteger(number)) {
    return parseInt(number.toString()); // Return the number as-is if there are no fractions
  } else {
    return number.toFixed(2); // Otherwise, show 2 decimal places
  }
}


export const calcProfit = (odds, stake, wholeOdds) => {
  if (wholeOdds) return parseFloat(((stake * (odds - 1)) || 0).toFixed(2));
  else return parseFloat(((stake * (odds / 100)) || 0).toFixed(2));
}

export const floatWith2DecimalCheck = (value) => {
  var re = /^\d+(\.\d{1,2})?$/;
  if (!value) return '';
  if (re.test(value)) {
    return parseFloat(value)
  }
  return null;
}
 
export const numberWithComma = (value) =>
  new Intl.NumberFormat("en-IN", {
    minimumFractionDigits: 0,
    currency: "INR",
  }).format(value);


export const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

export const blockInvalidCharWithDecmial = e => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();

export const getIP = async () => {
  // const res = await axios.get(`https://api-bdc.net/data/ip-geolocation-full?key=bdc_655c0adebc9040d891bac02d6ea38dfd`);
  // // console.log(res.data);
  // if(res && res.data && res.data.ip) return res.data.ip;
  // else return null
  return "192.168.0.1"
};

export const FIREBASE_COLLECTION = {
  USERS: `users`,
  UN_MATCH_TO_MATCH: `un-match-to-match`,
  UN_MATCH_LIST: 'un-match-list',
  USER_BALANCE: 'user-balance'
};

export async function getLogoLink() {
  try {
    
    const url = window.location.origin;
    console.log(url);
    
    // return result.ok;
    // Known second-level domain TLDs that include country codes
    const secondLevelTLDs = ['co.uk', 'com.au', 'co.nz', 'co.za', 'com.sg'];
  
    // Remove protocol (http, https) and get the hostname
    const hostname = url.replace(/(https?:\/\/)?(www\.)?/, '').split('/')[0]; // Also remove path
  
    // Split hostname into parts
    const parts = hostname.split('.');
  
    // Initialize domain
    let domain;
  
    // Check against known second-level TLDs
    const lastPart = parts.slice(-2).join('.');
    if (secondLevelTLDs.includes(lastPart)) {
      // For known second-level TLDs, the domain is the third last part
      domain = parts[parts.length - 3];
    } else if (parts.length > 2) {
      // If not a known second-level TLD but has more than 2 parts, assume the second last part is the main domain
      domain = parts[parts.length - 2];
    } else {
      // If there are only two parts, the first part is the domain
      domain = parts[0];
    }
  
    // if(domain.includes("localhost")) return `${process.env.REACT_APP_CONFIG_BUCKET_LINK}/maverick777/logo.png`;
  
    const result = await fetch(`${process.env.REACT_APP_CONFIG_BUCKET_LINK}/${domain}/logo.png`, { method: 'HEAD' });
    if(result.ok){
      return `${process.env.REACT_APP_CONFIG_BUCKET_LINK}/${domain}/logo.png`;
    }
  
    return `${process.env.REACT_APP_CONFIG_BUCKET_LINK}/maverick777/logo.png`;
  } catch (error) {
    return `${process.env.REACT_APP_CONFIG_BUCKET_LINK}/maverick777/logo.png`;
  }
}