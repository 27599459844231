
import Desktop from './desktop';
import Mobile from './mobile';
import Router from './router';

function App() {

  return (
    <div className="App">
      {/* <Router /> */}
      {
        window.innerWidth > 900 ? <Desktop /> : <Mobile />
      }
      
    </div>
  );
}

export default App;
