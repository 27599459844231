import moment from "moment";

 const indexDB = 
    window.indexedDB || 
    window.mozIndexDB || 
    window.webkitIndexDB ||
    window.msIndexDB || 
    window.shimIndexDB;



export const createOrConnectToDb = () =>{

    const request = indexDB.open("InternetLogs",1);

    request.onerror = function (event) {
        console.error("An Error in IndexDB ")
        console.error(event)
    }


    request.onupgradeneeded = function () {
        const db = request.result;
        const store = db.createObjectStore("logs",{keyPath:"id"});
        store.createIndex("live_time",["status"],{unique:false});
    }
}

export const createLog = (isOnline) =>{
    const request = indexDB.open("InternetLogs",1);

    request.onerror = (event) =>{
        console.log(event)
    }

    request.onsuccess = function () {
        const db = request.result;
        const transaction = db.transaction("logs","readwrite");
        
        const store = transaction.objectStore("logs");

        const addQuery = store.put({
            id:moment().unix(),
            timeStamp:moment().unix(),
            status:isOnline
        },);

        addQuery.onsuccess = function(){
            transaction.oncomplete = () =>{
                db.close();
            }
        }

        addQuery.onerror = (event) =>{
            console.log(event)
        }
        
    }
}

    